import { Artform } from '../../interfaces/artistPageItem/artistPageItem';

export const artistImages = {
	'visual arts':
		'https://images.prismic.io/platform-gatt/199cfb80-5d07-41cb-b644-5be2a3a359d6_GATT_www_ARTFORM_VISUALARTS_001.jpg?auto=compress,format',
	'performance arts':
		'https://images.prismic.io/platform-gatt/61ae596a-1fab-4003-9016-411998b94a39_GATT_www_ARTFORM_PERFORMACEARTS_001.jpg?auto=compress,format',
	music:
		'https://images.prismic.io/platform-gatt/bd2b6d25-25e0-4cee-8274-ccbf84eb4ac4_GATT_www_ARTFORM_MUSIC.001.jpg?auto=compress,format',
	literature:
		'https://images.prismic.io/platform-gatt/a6b6629c-db4f-4146-a834-22d3e30a3637_GATT_www_ARTFORM_LITERATURE_001.jpg?auto=compress,format',
	design:
		'https://images.prismic.io/platform-gatt/a610da41-c8c6-4714-8107-ca1f360206a3_GATT_www_ARTFORM_DESIGN_001.jpg?auto=compress,format',
};

export const mapArtformToImage = (artforms: Artform[]) => {
	let image = artistImages['visual arts'];
	artforms.forEach((item) => {
        const artform = item.artform.toLowerCase();
		if (artistImages[artform]) {
			image = artistImages[artform];
		}
	});

	return image;
};
