import React, { FC } from 'react';
import { Wrapper, Container, Circle, SkewLine, CircleSkewContainer, UrlText } from './fancyLink.styles';

interface Props {
	text: string;
	url: string;
}

const FancyLink: FC<Props> = ({ text, url }) => {
	return (
		<Wrapper>
			<Container>
				<CircleSkewContainer>
					<Circle />
					<SkewLine />
				</CircleSkewContainer>
				<UrlText href={url} target="_blank">
					{text}
				</UrlText>
			</Container>
		</Wrapper>
	);
};

export default FancyLink;
