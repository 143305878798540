import { graphql } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';
import ArtistContent from '../components/artist/artistContent';
import IArtistPageItem from '../interfaces/artistPageItem/artistPageItem';

const StyledArtist = styled.div`
	background-color: ${({ backgroundColor }) => backgroundColor};
`;

interface Props {
	data: {
		prismicArtist: IArtistPageItem;
	};
}

const Artist: FC<Props> = ({ data: { prismicArtist } }) => {
	return (
		<StyledArtist>
			<ArtistContent artist={prismicArtist} />
		</StyledArtist>
	);
};

export default Artist;

export const pageQuery = graphql`
	query ArtistBySlug($uid: String!) {
		prismicArtist(uid: { eq: $uid }, data: { name: { text: { ne: "Ignored-dummy-artist" } } }) {
			uid
			data {
				name {
					text
				}
				main_image {
					url
					alt
					detail_mobile {
						url
					}
					detail_tablet {
						url
					}
					detail_desktop {
						url
					}
					detail_retina {
						url
					}
				}
				events {
					event {
						uid
					}
				}
				artforms {
					artform
				}
				country
				summary {
					html
				}
				main_text {
					html
				}
				images {
					gallery_images {
						url
						alt
						Retina_image {
							url
						}
						Thumbnail {
							url
						}
					}
				}
				audio {
					url
				}
				video {
					url
				}
				website_url {
					url
					target
				}
			}
		}
	}
`;
