import styled, { keyframes, css } from 'styled-components';
import media from '../../../constants/media';
import { Column } from '../../grid/grid';

const Animation = keyframes`
	0% { transform: translate3d(0, 0, 0); }
	100% { transform: translate3d(0, -100%, 0); }
`;

export const Wrapper = styled.div`
	padding: 100px 20px 100px 20px;
	color: black;

	background-image: url(${props => props.image});
	background-size: 100% auto;
	background-repeat: repeat-y;
	background-position: center top;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #fff;

		${({ show }) =>
			show &&
			css`
				animation: ${Animation} 350ms ease forwards;
				animation-delay: 350ms;
			`}
	}

	[name='Column'] {
		min-width: 325px;
		margin-bottom: 0px;
	}
	[name='Column']:nth-child(2) {
		[name='Column']:nth-child(2) {
			margin-top: 200px;
		}
	}
	${media.mobileOnly`
	[name="Column"]:nth-child(2){
		[name="Column"]:nth-child(2){
			margin-top: 80px;
		}	
	}
	`}

	${media.tabletOnly`
		[name="Column"]{
			[name="Column"]{
				max-width: 500px;
			}	
		}	
		[name="Column"]:nth-child(2){
			[name="Column"]:nth-child(2){
				margin-top: 80px;
			}	
		}
	`}
`;

export const PictureColumn = styled(Column)`
	padding-left: 100px;
`;

export const StyledLinkContainer = styled.div`
	margin-top: 40px;
	margin-bottom: 30px;
	margin-left: auto;
	margin-right: 0;
	width: 210px;
`;
export const StyledPictureContainer = styled.div`
	background-image: url(${({ mobile, defaultimg }) => mobile || defaultimg});
	padding-top: 120%;
	background-size: cover;
	background-position: center top;
	
	${({ tablet }) =>
		tablet &&
		media.tablet`
		background-image: url(${tablet});
	`}

	${({ desktop }) =>
		desktop &&
		media.desktopLarge`
		background-image: url(${desktop});
	`}

	${({ retina }) =>
		retina &&
		media.retina`
		background-image: url(${retina});
	`}
`;

export const StyledPicture = styled.picture``;

export const StyledArtform = styled.div`
	font-size: 2.2rem;
	font-weight: 200;
	text-transform: uppercase;
	padding: 0 0 2rem 0;
`;

export const StyledHeading = styled.h2`
	font-size: 2.6rem;
	font-weight: 600;
	line-height: 1.166;
`;

export const StyledSubHeading = styled.h3`
	font-size: 2rem;
	font-weight: 600;
	line-height: 1.166;
	margin-bottom: 30px;
`;
