import React, { FC, useState, useContext } from 'react';
import Interweave from 'interweave';
import AppContext from '../../../contexts/appContext/appContext';
import IArtistPageItem from '../../../interfaces/artistPageItem/artistPageItem';
import useOnMount from '../../../hooks/useOnMount';
import { nullCheckImage } from '../../../helpers/imageHelpers/nullCheckImage';
import {
	StyledArtform,
	StyledHeading,
	StyledSubHeading,
	Wrapper,
	StyledPictureContainer,
	PictureColumn,
	StyledLinkContainer,
} from './artistContent.styles';
import { Column, Row } from '../../grid/grid';
import FancyLink from '../../fancyLink/fancyLink';
import { mapArtformToImage } from '../../../helpers/imageHelpers/artistImages';

interface Props {
	artist: IArtistPageItem;
}

const ArtistContent: FC<Props> = ({ artist }) => {
	// Interweave uses the document object which breaks the code on build time
	const [mounted, setMounted] = useState(false);
	useOnMount(() => setMounted(true));
	const { isAnimationFinished } = useContext(AppContext);
	const backgroundImage = mapArtformToImage(artist.data.artforms);

	return (
		<Wrapper show={isAnimationFinished} image={backgroundImage}>
			<Row>
				<PictureColumn flex="1">
					<StyledPictureContainer
						retina={nullCheckImage(artist.data.main_image.detail_retina.url)}
						desktop={nullCheckImage(artist.data.main_image.detail_desktop.url)}
						tablet={nullCheckImage(artist.data.main_image.detail_tablet.url)}
						mobile={nullCheckImage(artist.data.main_image.detail_mobile.url)}
						defaultimg={artist.data.main_image.url}
					/>
					{artist.data.website_url && (
						<StyledLinkContainer>
							{mounted && <FancyLink text="go to website" url={artist.data.website_url.url} />}
						</StyledLinkContainer>
					)}
				</PictureColumn>
				<Column flex="2">
					<Row>
						<Column flex="1">
							<StyledHeading>{mounted && <Interweave content={artist.data.name.text} />}</StyledHeading>
							<StyledArtform>
								{' '}
								{artist.data.artforms.map((artform, index) => (
									<span key={index}>
										{index > 0 && <span>, </span>}
										<span>{artform.artform}</span>
									</span>
								))}
							</StyledArtform>
							{mounted && <Interweave content={artist.data.summary.html} />}
						</Column>
						<Column flex="1">
							<StyledSubHeading>{mounted && <Interweave content="Recent works" />}</StyledSubHeading>
							{mounted && <Interweave content={artist.data.main_text.html} />}
						</Column>
					</Row>
				</Column>
			</Row>
		</Wrapper>
	);
};

export default ArtistContent;
