import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

export const Wrapper = styled.div`
	position: relative;
`;

export const Container = styled.div`
	position: relative;
	width: 210px;
	height: 55px;
`;

export const Circle = styled.div`
	position: absolute;
	height: 20px;
	width: 20px;
	border-radius: 100%;
	background-color: black;
	right: 0;
`;

export const CircleSkewContainer = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	width: 100px;
	height: 40px;
	overflow: hidden;
	transform: rotate(-10deg);
`;

export const SkewLine = styled.div`
	position: absolute;
	top: 10px;
	right: -40px;
	width: 110px;
	height: 100px;
	border-style: solid;
	border-width: 1px;
	border-color: black;
	border-radius: 50%;
`;

export const UrlText = styled.a`
	color: black;
	font-weight: 400;
	position: absolute;
	bottom: -2px;
`;
